.home-page {
  min-height: 100vh; /* Ensure minimum height of the viewport */
  overflow-y: auto; /* Enable vertical scrolling */
}

  image-container {
    background-color: #EDFDFE; /* Set the specific background color */
    position:relative;
    width: 100%; /* Adjust as needed */
    height: 500px; /* Adjust as needed */
    align-items: center;
  }
  
  .bg-image-home {
    position: absolute;
    margin-right: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./tecdev.png');
    background-size:auto;
    background-repeat: no-repeat;
    z-index: 2; /* Initially on top */
    transition: filter 2s ease-in-out, z-index 0s 2s; /* Transition filter and delay z-index change */
    
}

.card {
  border: 2px solid #333; /* Darker border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6); /* Keep the shadow */
}
  
  .bg-image-home.blur {
    filter: blur(10px);
    z-index: -1; /* Send to back after transition */
  }
  
  .content {
    position: relative;
    z-index: 1;
    padding: 30px;
    color: #EDFDFE;
    /* Additional content styles */
  }
  
  
  .black-text {
    color: black;
  }

  .body
  {
    background-color: #EDFCFF;
  }

  .dashboard-header {
    background-color: #2C476C;
    
  }


  .trainer-dashboard-title {
    color: rgb(197, 31, 31);
    text-align: center;
    
  }
  
  .dashboard-cards {
    display: flex;
    justify-content: space-evenly; /* Even spacing between cards */
    align-items: center;
    flex-wrap: nowrap; /* Prevents wrapping to the next line */
  }
  
  .dashboard-card {
    flex-basis: 30%; /* Adjust the basis of each card */
    margin: 10px;
    padding: 10px;
    background-color: #65758A;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  
  
  .dashboard-card .card-title {
    font-size: 1.2em;
    color: #f6f5f5;
    margin-bottom: 15px;
  }
  
  .dashboard-card .card-value {
    font-size: 2em;
    font-weight: bold;
    color: #ccd93f;
  }
  
 