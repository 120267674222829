.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Allows scrolling */
}

.detail-container {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc; /* This adds a light grey line */
}

/* This removes the border from the last detail container */
.detail-container:last-child {
  border-bottom: none;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 90%; /* Ensures modal does not get too wide on large screens */
  margin: 10px; /* Adds some space around the modal */
}


/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .modal-content {
    width: 100%; /* Full width on smaller screens */
    max-width: none; /* Overrides the max-width set earlier */
    box-shadow: none; /* Optional: removes shadow for a cleaner look */
  }

  .button-container {
    flex-direction: column; /* Stacks buttons vertically */
    align-items: flex-start; /* Aligns buttons to the start */
  }

  .withdraw,
  .btn-danger,
  .mr-4 {
    width: 100%; /* Full width buttons */
    margin-top: 10px; /* Space between buttons */
  }

  .appeal-pending {
    margin-top: 10px; /* Space above the appeal pending text */
  }
}

.modal-body {
  margin: 20px 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}


.withdraw {
  background-color: #ffc107 !important; /* Bootstrap's yellow color */
  color: black !important;
  border: none !important;
}


.withdraw:hover {
  background-color: #e0a800; /* A darker shade of yellow for hover */
}


.btn-appeal {
  background-color: #dc3545 !important; /* Red color */
  color: white;
  border: none;
}

.btn-appeal:hover {
  background-color: #c82333; /* Darker red for hover */
}

.button-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the gap between buttons as needed */
}

button:hover {
  background-color: #566103;
}




.appeal-pending {
  margin-top: 4px; /* Adjust margin as needed */
  font-weight: bold;
  color: #ff9900; /* Adjust the color as needed */
}

@keyframes flashingText {
  0%, 100% { 
    color: rgb(244, 102, 102); /* Lighter red */
  }
  50% { 
    color: rgb(135, 7, 7); /* Darker red */
  }
}

.appeal-pending {
  font-weight: bold;
  animation: flashingText 1.5s infinite;
}