.App {
  text-align: center;
}

/* App.css */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.background-image {
  position: fixed; /* Fixed position */
  top: 120px;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./tecdev.png'); /* Path to your image */
  background-size: 65%; /* Adjust percentage as needed */
  background-position: center; /* Keep the image centered */
  background-repeat: no-repeat;
  filter: blur(9px); /* Apply blur */
  z-index: -1; /* Send to back */
}

/* Ensure other elements are not affected by the blur */
.content, .navbar, .footer, .any-other-class {
  position: relative;
  z-index: 1; /* Above the background layer */
  background: transparent;
}

.admin-table th {
  border: 1px solid #040404; 
  background-color: #84a4a7;
  /* Bootstrap default */
}

.admin-table td {
  border: 1px solid #040404;
  background: #fefefe; 
  /* Bootstrap default */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-custom-modal {
  position: fixed; /* Stay in place */
  z-index: 1050; /* Sit on top of everything */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.my-custom-modal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 50%; /* Adjust the width as needed */
  /* Additional styles */
}


/* Navbar.css */
.nav-link.active {
  border: 2px solid white; /* White outline */
  border-radius: 5px;      /* Optional: rounded corners */
  padding: 5px;            /* Optional: for better visual spacing */
}


/* Add this to your CSS file (e.g., LoadingComponent.css) */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  position: fixed; /* Optional: makes it overlay on top of other content */
  top: 0;
  left: 0;
  z-index: 1050; /* Optional: to ensure it's above other content */
}

.loading-container img {
  max-width: 900px; /* or your desired width */
  max-height: 900px; /* or your desired height */
  border-radius: 50%; /* Makes the image round */
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.form-check {
  background-color: aliceblue;
}


.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
  text-align: center;
}

.login-container h1, .login-container button {
  margin: 10px 0; /* Spacing between elements */
}

/* Optional: Style for the button */
.login-container button {
  padding: 10px 20px;
  background-color: #FA6739;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #264367;
}

.login-container img {
  max-width: 140px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space between image and text */
}
