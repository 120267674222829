.grader-admin-dashboard table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .grader-admin-dashboard th, .grader-admin-dashboard td {
    border: 1px solid #ddd;
    padding: 8px;
    background-color: #f4f4f4;
  }
  
  .grader-admin-dashboard th {
    background-color: #f4f4f4;
    text-align: left;
  }
  