/* RegradeModal.css */

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 80%;
    max-width: 600px;
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content p {
    margin: 10px 0;
  }
  
  .modal-content button {
    padding: 10px 20px;
    margin-top: 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    float: right;
  }
  
  .modal-content button:hover {
    background-color: #0056b3;
  }
  