
/* DetailsPage.css */
.details-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card {
  width: 340px; /* Adjust the width as needed */
  box-shadow: 0 6px 10px 0 rgba(0,0,0,0.5);
  transition: 0.3s;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card-body {
  padding: 10px;
}

.card-title {
  margin-bottom: 15px;
  color: rgb(6, 6, 6);
}

.course-status-completed {
  font-weight: bold;
  color: green;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover i {
  color: #0056b3; /* Change color on hover */
  transform: scale(1.1); /* Slightly increase size */
}

.icon-button:focus {
  outline: none; /* Remove focus outline */
}



@keyframes flashingText {
  0%, 100% { 
    color: rgb(244, 102, 102); /* Lighter red */
  }
  50% { 
    color: rgb(135, 7, 7); /* Darker red */
  }
}

.course-status-started, .course-status-empty {
  font-weight: bold;
  animation: flashingText 1.5s infinite;
}



@keyframes flashing {
  0%, 100% { 
    color: rgb(121, 139, 241); 
    transform: scale(1);
  }
  50% { 
    color: rgb(8, 24, 115); 
    transform: scale(5.5); 
  }
}

.flash-icon {
  animation: flashing 1.5s infinite;
}

  

  
  

  .bg-image {
    position: absolute;
    margin-left: 20%;
    top: 40px;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../tecdev.png');
    background-size:auto;
    background-repeat: no-repeat;
    z-index: 2; /* Initially on top */
    transition: filter 2s ease-in-out, z-index 0s 2s; /* Transition filter and delay z-index change */
    
}

  image-container {
    background-color: #EDFDFE; /* Set the specific background color */
    position:sticky;
    width: 100%; /* Adjust as needed */
    height: 500px; /* Adjust as needed */
    

    
    
    
  }

  .bg-image.blur {
    filter: blur(10px);
    z-index: -1; /* Send to back after transition */
    
    
  }


 
