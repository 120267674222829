
.modal-background1 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal1 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    max-width: 500px;
    width: 100%;
    /* Centering Modal Vertically and Horizontally */
    margin: auto;
    overflow: auto; /* Add scrolling to the entire modal */
    max-height: 80vh; /* Set a maximum height */
  }
  
  .modal1 h3 {
    margin-top: 0;
  }
  
  .tabled {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    display: block; /* Allows for scrolling */
    max-height: 300px; /* Set a maximum height for the table */
    overflow-y: auto; /* Add vertical scrollbar to the table */
  }
  
  table th,
  table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  table th {
    background-color: #f4f4f4;
  }
  
  button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 15px;
  }
  
  button:hover {
    background-color: #0056b3;
  }

  .dashboard-card:hover {
    cursor: pointer;
  }
  